import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import WithNavigationToHome from "../components/WithNavigationToHome";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { LayoutGrid, LayoutGridCell } from "@cdk-uip/react-layout-grid";
import "./style.css";
import { PrimaryButton } from "cdk-radial";
import BundleList from "./view";

function ApiBundleList({ auth }) {
  const history = useHistory();
  const { accessToken } = auth;
  const [bundleList, setBundleList] = useState([]);
  const [unAuthorizedUser, setUnAuthorizedUser] = useState(true);
  const handleCreateBundle = () => {
    history.push({
      pathname: "/bundle-management/create",
      state: {
        bundlesList: bundleList
      }
    });
  };

  return (
    <Fragment>
      <LayoutGrid
        className={
          "s-content-route--subscription-management c-layout-section__dash-grid"
        }
      >
        <LayoutGridCell
          className={"c-layout-section__dash-grid-cell"}
          span={12}
          spanTablet={8}
          spanPhone={4}
        >
          {!unAuthorizedUser && (
            <div className="create_bundle_list_button">
              <PrimaryButton
                text="Create Bundle"
                onClick={handleCreateBundle}
              />
            </div>
          )}
          <BundleList
            bundleList={bundleList}
            setBundleList={setBundleList}
            setUnAuthorizedUser={setUnAuthorizedUser}
            accessToken={accessToken}
          />
        </LayoutGridCell>
      </LayoutGrid>
    </Fragment>
  );
}

ApiBundleList = WithNavigationToHome(ApiBundleList);
class createContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <ApiBundleList {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(ApiBundleList);