import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card, PrimaryButton } from "cdk-radial";

import { AvatarWrapper, AVATAR_SIZES, IconLockOutline } from "cdk-radial";
import { Content, CONTENT_TYPES } from "cdk-radial";
import { Button, BUTTON_VARIANTS, Dialog } from "cdk-radial";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import "./style.css";
import axios from "axios";
import config from "../config/app.conf.json";

const BundleCard = (props) => {
  const [bundleId, setBundleId] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [createError, setCreateError] = useState(false);
  const history = useHistory();
  const {
    apiBundle: { apis, name, description, id, weight, status, visibility },
    setBundleShareName,
    setBundleShareId,
    isVersion2,
    setScreen,
    setSelectedBundle
  } = props;
  const handleEdit = () => {
    if(isVersion2){
      setSelectedBundle(props?.apiBundle)
      setScreen("Edit Bundles")
    }
  else{
    let restId = [];
    let asyncId = [];

    apis.forEach((e) => {
      if (e.type === "api") {
        restId.push(e.id);
      }
    });
    apis.forEach((e) => { 
      if (e.type === "async-api") {
        asyncId.push(e.id);
      }
    });
    history.push({
      pathname: `/bundle-management/edit/${id}`,
      state: {
        id: id,
        name: name,
        description: description,
        apis: apis,
        restApi: restId,
        asyncApi: asyncId,
        weight: weight,
        bundleList: props.bundleList,
      },
    });
  }
  };
  const handleBundleShare=()=>{
    setScreen("Share Bundles")
    setBundleShareId(id)
    setBundleShareName(name)
  }

  const handleDelete = () => {
    setConfirmDialogOpen(true);
  };

  const handleRedirect = () => {

    if(isVersion2){
      history.push("/bundle-management");
    }
    else{
      history.push("/bundle-management/view-all");
    }
  }

  const handlePatchUpdate = () => {
    let stats;
    stats = status === "active" ? "inactive" : "active";
    axios
      .patch(
        `${config.api.api_gateway_url}/v1/admin/bundles/${id}`,
        {
          status: stats,
        },
        {
          headers: {
            Authorization: `Bearer ${props.auth.accessToken}`,
          },
        }
      )
      .then(() => {
        setCreateError(false);
        handleRedirect()
      })
      .catch((error) => {
        console.log(error);
        setCreateError(true);
      });
  };

  const handleDeleteBundle = () => {
    axios
      .patch(
        `${config.api.api_gateway_url}/v2/admin/bundle/${id}`,
        { status: 'retired' }, {
        headers: {
          Authorization: `Bearer ${props.auth.accessToken}`,
        },
      })
      .then(() => {
        setConfirmDialogOpen(false);
        handleRedirect()
      })
      .catch((error) => {
        console.log(error);
        setConfirmDialogOpen(false);
        handleRedirect()
      });
  };

  const dialogContent = (
    <Content type={CONTENT_TYPES.BODY_2}>
      This operation will delete the bundle permanently, would you like to
      continue ?
    </Content>
  );
  return !name && !description && !apis ? null : (
    <>
      {confirmDialogOpen ? (
        <Dialog
          className="share-api-dialog"
          buttonsProps={[
            {
              id: "cancel-action",
              onClick: () => setConfirmDialogOpen(false),
              text: "Cancel",
              variant: BUTTON_VARIANTS.SECONDARY,
            },
            {
              id: "share-action",
              onClick: () => handleDeleteBundle(),
              text: "Delete",
              variant: BUTTON_VARIANTS.PRIMARY,
            },
          ]}
          id="share-api-dialog"
          isOpen={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          title="Confirm Delete"
        >
          {dialogContent}
        </Dialog>
      ) : null}
      {createError ? (
        <Dialog
          className="create-api-dialog"
          buttonsProps={[
            {
              id: "cancel-action",
              onClick: () => setCreateError(false),
              text: "Cancel",
              variant: BUTTON_VARIANTS.SECONDARY,
            },
          ]}
          id="share-api-dialog"
          isOpen={createError}
          onClose={() => setCreateError(false)}
          title="Bundle Creation Failed"
        >
          {"Failed to update API Bundle Status"}
        </Dialog>
      ) : null}
      <Card
        data-cy={`spec-card__${name}`}
        className="bundle_spec-card"
        dataTestId="bundle_card"
      >
        <div className="bundle_card_top_div">
          <div className="logo_name_icon_wrapper">
            <div className="bundle_card_logo">
              <AvatarWrapper
                style={{
                  width: "48px",
                  height: "48px",
                  left: "16px",
                  top: "16px",
                  backgroundColor: "#5E2368",
                  dataTestId: "bundle_avtar",
                }}
                size={AVATAR_SIZES.STANDARD}
              >
                <Content
                  style={{
                    color: "white",
                    fontFamily: "roboto",
                    fontWeight: "500",
                    fontize: "18px",
                    lineHeight: "24px",
                  }}
                  dataTestId="bundle_descryption"
                >
                  CDK
                </Content>
              </AvatarWrapper>

              <div
                className={
                  name.length > 46 ? "bundle_title_3" : "bundle_title_2"
                }
              >
                {name}
              </div>
            </div>
            {visibility === "private" && <IconLockOutline></IconLockOutline>}
          </div>
          <div
            className={
              status === "active"
                ? "bundle_card_status_green"
                : "bundle_card_status_red"
            }
          >
            {`${status.toUpperCase()}`}
          </div>
          <div className="bundle_card_description">{description}</div>
          <div className="list_apis">Included APIs ({apis.length})</div>
        </div>
        <div
          className={`bundle_card_middle_div ${
            apis.length > 3 ? "background" : ""
          }`}
        >
          <div
            className={
              apis.length > 3 ? "bundle_apis_scroll_gray" : "bundle_apis_scroll"
            }
          >
            {apis.map(function (api) {
              return <div className="api-font">{api.name}</div>;
            })}
          </div>
        </div>
        <div className="bundle_card_bottom_div">
          <div className="bundle_card__ruler" />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              padding: "5px",
            }}
          >
            { !!isVersion2 &&
            <div className="bundle_card-share-button">
              <PrimaryButton
                dataTestId="build_with_bundle"
                iconColor="primary"
                text="Share"
                onClick={handleBundleShare}
                isDisabled={visibility === "public" || status !== 'active'  ? true : false}
              />
            </div>
            }
            <div className="bundle_card-edit-button">
              <PrimaryButton
                dataTestId="build_with_bundle"
                iconColor="primary"
                text="Edit"
                onClick={handleEdit}
              />
            </div>
            <div className="bundle_card-delete-button">
              <PrimaryButton
                dataTestId="build_with_bundle"
                iconColor="primary"
                text="Delete"
                onClick={handleDelete}
              />
            </div>
            <div className="bundle_card-status-button">
              <PrimaryButton
                dataTestId="build_with_bundle"
                iconColor="primary"
                text={status === "active" ? "Deactivate" : "Activate"}
                onClick={handlePatchUpdate}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

class bundleContainer extends React.Component {
  render() {
    return this.props.auth.isAuthenticated ? (
      <BundleCard {...this.props} />
    ) : (
      <div></div>
    );
  }
}
export default withAuth(bundleContainer);
